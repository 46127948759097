.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 8vw;
    min-height: 60px;
    max-height: 100px;
    gap: 7.63vw;
    padding: 15px 20px;
}

.header__pencil {
    max-width: 38vw;
    width: 375px;
}

.header__logo {
    width: 215px;
    max-width: 40vw;
    max-height: 100%;
    height: 100%;
}