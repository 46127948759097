@use 'config';
@use 'libs/styles/responsive';

body.loader-visible {
    overflow: hidden;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease;
    transform: translateY(0);

    &.loader--hidden {
        transform: translateY(-100%);
    }

    @include responsive.target(lg, xxl) {
        flex-direction: row;
    }
}

.loader__top,
.loader__bottom {
    position: relative;
    height: 50%;
    overflow: hidden;

    @include responsive.target(lg, xxl) {
        height: 100%;
        width: 50%;
    }
}

.loader__top__slogan {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: 0;
    color: config.$color-red;
    font-size: 28px;
    z-index: 1;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;

    @include responsive.target(xl, xxl) {
        font-size: 34px;
        top: 70px;
    }
}

.text-mask {
    display: block;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.45s ease, opacity 0.45s ease;

    &.text-mask--visible {
        opacity: 1;
        transform: translateY(0) !important;
    }
}

.loader__top__first,
.loader__top__second {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.loader__top__second {
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.loader__top__second--visible {
        opacity: 1;
    }
}

.loader__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: config.$color-red;
    padding: 30px;
}

.loader__topline,
.loader__headline {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: center;
    overflow: hidden;
}

.loader__topline {
    font-size: 15px;
    margin-bottom: 0.75em;

    @include responsive.target(xl, xxl) {
        font-size: 18px;
    }
}

.loader__headline {
    font-size: 28px;

    .text-mask {
        transform: translateY(30px);
    }

    @include responsive.target(xl, xxl) {
        font-size: 34px;
        line-height: 1.38;
        max-width: 450px;
    }
}