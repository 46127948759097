@use 'config';

html,
body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: config.$color-red;
    color: config.$color-white;
}

img {
    display: block;
    max-width: 100%;
}