@use 'app/styles/config';
@import 'app/styles/fonts';
@import 'libs/styles/responsive';

*,
*::before,
*::after {
  @include nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-synthesis: none !important; // To prevent Safari creating `too bold` fonts.
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: "Montserrat", "Futura", "Century Gothic", "Arial", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: config.$color-white;
}

h1,
.headline1 {
  font-weight: 700;
  line-height: 1.3929;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 0.75em;

  @include target(md) {
    font-size: 32px;
  }

  @include target(lg) {
    font-size: 36px;
    line-height: 1.3;
  }

  @include target(xl, xxl) {
    font-size: 40px;
    line-height: 1.3;
  }
}

h2,
.headline2 {
  font-weight: 700;
  line-height: 1.3929;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 0.75em;

  @include target(md) {
    font-size: 28px;
  }

  @include target(lg) {
    font-size: 30px;
    line-height: 1.3;
  }

  @include target(xl, xxl) {
    font-size: 36px;
    line-height: 1.3;
  }
}

p,
.body-text {
  font-size: 16px;
  font-weight: 300;
  color: inherit;
  line-height: 1.25;
  margin-bottom: 1em;

  @include target(lg, xxl) {
    font-size: 20px;
  }

  a {
    color: config.$color-white;
    text-decoration: underline 1px;
    text-underline-offset: 4px;
    white-space: nowrap;
  }
}

.nobr {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}