@use 'libs/styles/responsive';
@use 'config';

.tnb {
    width: calc(100% - 70px);
    max-width: 1740px;
    margin: auto;
    text-align: center;
    padding: 20px;

    .header+& {
        min-height: calc(100vh - 270px);
    }

    @include responsive.target(lg, xxl) {
        padding: 30px;
        width: calc(100% - 140px);
    }
}

.tnb__closer {
    display: inline-block;
    margin-bottom: 25px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(config.$color-black, 0.5);

    @include responsive.target(lg, xxl) {
        display: flex;
        flex-direction: column;
    }

    .tnb {
        background-color: config.$color-black;
        border-radius: 20px;
        margin-top: 40px;
        max-height: calc(100% - 100px);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include responsive.target(lg, xxl) {
            margin-top: auto;
        }
    }

    .tnb__inner {
        max-height: calc(100% - 50px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}