@use 'config';
@use 'libs/styles/responsive';

section {
    &.references {
        padding: 40px 20px 20px;

        @include responsive.target(lg, xxl) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    &.products {
        margin: 20px;
        padding: 20px;
        background-color: config.$color-white;
        color: config.$color-red;
        text-align: center;
        border-radius: 20px;

        @include responsive.target(lg, xxl) {
            padding-top: 75px;
            padding-bottom: 15px;
            margin-left: 50px;
            margin-right: 50px;
            max-width: 1760px;
        }

        @media screen and (min-width: 1860px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.contest {
        text-align: center;
        margin: 20px auto;
        padding: 20px;
        width: 860px;
        max-width: calc(100vw - 40px);

        @include responsive.target(lg, xxl) {
            margin-top: 100px;
        }
    }

    &.contest-success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 20px auto;
        padding: 20px;
        width: 860px;
        max-width: calc(100vw - 40px);
        min-height: calc(100vh - 60px - 268px);
    }
}