@import 'config';
@import 'libs/styles/fonts';

@font-face {
    font-family: "Montserrat";
    src:
        url("/static/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format('truetype');
}


// @include generate-font(
//   $font-OpenSans,
//   $FontPath: '/static/fonts/open-sans/open-sans-v15-latin-',
//   $FontStyles: (
//     'regular': (),
//     'italic': (
//       style: $fs-italic,
//     ),
//     '300': (
//       weight: $fw-light,
//     ),
//     '300italic': (
//       style: $fs-italic,
//       weight: $fw-light,
//     ),
//     '600': (
//       weight: $fw-semi-bold,
//     ),
//     '600italic': (
//       style: $fs-italic,
//       weight: $fw-semi-bold,
//     ),
//     '700': (
//       weight: $fw-bold,
//     ),
//     '700italic': (
//       style: $fs-italic,
//       weight: $fw-bold,
//     ),
//     '800': (
//       weight: $fw-extra-bold,
//     ),
//     '800italic': (
//       style: $fs-italic,
//       weight: $fw-extra-bold,
//     )
//   ),
//   $FontFormats: ($ft-woff2, $ft-woff, $ft-eot),
//   $FontLigatures: True
// );