@use 'config';
@use 'libs/styles/responsive';

@mixin desktopFooter {
    @include responsive.target(lg, xxl) {
        @content
    }
}

.footer {
    background-color: config.$color-white;
    color: config.$color-red;
    padding: 50px 20px;
    font-size: 16px;

    a,
    p {
        color: inherit;
        text-decoration: none;
        font-size: inherit;
    }

    a {
        position: relative;

        &::after {
            display: block;
            content: " ";
            position: absolute;
            bottom: -2px;
            width: 100%;
            height: 0.5px;
            background-color: config.$color-red;
            transform: translateY(1px);
            opacity: 0;
            transition: transform 0.2s ease, opacity 0.2s ease;
        }

        &:hover {
            &::after {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    @include desktopFooter {
        padding: 50px;
    }
}

.footer__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid config.$color-red;
    padding-bottom: 40px;

    @include desktopFooter {
        padding-bottom: 60px;
    }
}

.footer__logo {
    width: 290px;
    display: none;

    @include desktopFooter {
        display: block;
    }
}

.footer__address {
    display: none;
    font-size: 20px;

    a {
        display: inline-block;

        &:not(:last-child) {
            margin-bottom: 0.25em;
        }
    }

    @include desktopFooter {
        display: block;
    }
}

.footer__socials {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 16px;

    @include desktopFooter {
        gap: 24px;
        width: auto;
    }

    a::after {
        display: none;
    }
}

.footer__socials__item {
    a {
        &:hover {
            img {
                filter: grayscale(1);
            }
        }
    }

    img {
        width: 25px;
        height: 25px;
        transition: filter 0.25s ease;

        @include desktopFooter {
            width: 36px;
            height: 36px;
        }
    }
}

.footer__bottom {
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    align-items: center;

    @include desktopFooter {
        flex-direction: row;
        justify-content: space-between;
    }
}

.footer__meta {

    ul {
        display: flex;
        flex-direction: row;
        gap: 20px;

        @include desktopFooter {
            gap: 30px;
            font-size: 20px;
        }
    }
}

.footer__copyright {
    margin-bottom: 26px;

    @include desktopFooter {
        margin-bottom: 0;
    }
}