@use 'libs/styles/responsive';

.image-gallery {
    max-width: 1760px;
    margin: auto;
}

.image-gallery img {
    border-radius: 20px;
}

.splide__arrow {
    background: url('/static/img/arrow-down.svg') 0 0 no-repeat;
    background-size: contain;
    filter: brightness(0) invert(1);
    opacity: 1;

    svg {
        display: none;
    }

    &.splide__arrow--next {
        transform: rotate(-90deg);
    }

    &.splide__arrow--prev {
        transform: rotate(90deg);
    }

    @include responsive.target(md, xxl) {
        width: 40px;
        height: 40px;
    }
}