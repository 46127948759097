@use 'config';
@use 'libs/styles/responsive';

.contest__form {
    width: 84%;
    min-width: 240px;
    margin: 50px auto 40px;
}

.contest__tnb {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.4;
    color: config.$color-white;

    @include responsive.target(lg, xxl) {
        margin-bottom: 150px;
    }
}