@use 'libs/styles/responsive';

@mixin desktopIntro {
    @media screen and (min-width: 1024px) and (min-height: 700px) {
        @content;
    }
}

.intro {
    background: rgb(76, 78, 83);
    position: relative;

    @include desktopIntro {
        background: transparent;
        height: calc(100vh - 100px);
    }
}

.intro__inner {
    margin-top: -150px;

    @include desktopIntro {
        max-width: 1760px;
        height: 100%;
        margin: auto;
    }
}

.intro__content {
    position: relative;
    padding: 80px 30px 40px;
    background: linear-gradient(0deg, rgba(52, 53, 56, 1) 0%, rgba(117, 118, 120, 1) 80%, rgba(119, 120, 122, 0) 100%);

    @include desktopIntro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 50%;
        max-width: 600px;
        height: 100%;
        padding-bottom: 80px;
        background: transparent;
    }
}

.intro__visual {
    margin: auto;
    max-height: 70vh;
    width: 100%;
    object-fit: cover;
    object-position: center;

    @include desktopIntro {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 0;
        max-height: unset;
    }
}

@keyframes arrowBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.intro__arrow {
    display: none;
    position: absolute;
    bottom: 25px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: url('/static/img/arrow-down.svg') 0 0 no-repeat;
    background-size: contain;
    overflow: hidden;
    color: transparent;

    @include desktopIntro {
        display: block;
        animation: arrowBounce 2.25s 3s infinite;
    }
}