@use 'config';
@use 'libs/styles/responsive';

.btn {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    font-family: inherit;
    letter-spacing: 0.1em;
    line-height: 2;
    background-color: config.$color-red;
    text-transform: uppercase;
    color: config.$color-white;
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 20px;
    border: 1px solid config.$color-red;
    transition: border-color 0.1s ease, box-shadow 0.1s ease;
    cursor: pointer;

    &:hover {
        border-color: lighten(config.$color-red, 10);
        box-shadow: 0px 0px 10px rgba(config.$color-white, 0.1);
    }

    &.btn--white {
        background-color: config.$color-white;
        border: 1px solid config.$color-white;
        color: config.$color-red;
        text-transform: none;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.01em;
        line-height: 1.575;

        @include responsive.target(lg, xxl) {
            font-size: 20px;
            line-height: 1.4;
        }


        &:hover {
            border-color: darken(config.$color-white, 10);
            box-shadow: 0px 0px 10px rgba(config.$color-white, 0.25);
        }
    }
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include responsive.target(lg, xxl) {
        gap: 40px;
    }
}

.form-row {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include responsive.target(lg, xxl) {
        flex-direction: row;
        gap: 40px;
    }
}

.form-row__field {
    width: 100%;

    &.form-row__field--checkbox {
        position: relative;

        input[type="checkbox"] {
            width: auto;
            opacity: 0;
            position: absolute;
            left: 0;

            &+label {
                display: block;
                position: relative;
                padding-left: 30px;
                text-align: left;

                &:before {
                    content: " ";
                    display: block;
                    left: 0;
                    top: -2px;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: config.$color-white;
                    cursor: pointer;
                    border-radius: 5px;
                }
            }

            &:checked {
                &+label:before {
                    outline: 1px solid config.$color-black;
                }

                &+label:after {
                    content: " ";
                    background: url('/static/img/cross.svg') center no-repeat;
                    background-size: contain;
                    filter: invert(1);
                    position: absolute;
                    height: 12px;
                    width: 12px;
                    left: 4px;
                    top: 2px;
                    cursor: pointer;
                }
            }
        }
    }

    input {
        width: 100%;
        font-size: 16px;
        padding: 10px;
        border: 1px solid config.$color-white;
        border-radius: 100px;
        font-family: inherit;
        text-align: center;
        background-color: config.$color-red;
        color: config.$color-white;
        line-height: 1.1;

        @include responsive.target(lg, xxl) {
            font-size: 20px;
            padding: 8px 10px;
        }

        &::placeholder {
            color: darken(config.$color-white, 8);
            opacity: 0.75;
            /* Firefox */
        }

        &::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: darken(config.$color-white, 8);
        }

        &:focus,
        &:focus-visible {
            outline: 1px solid config.$color-white;
        }
    }

    label {
        a {
            margin-bottom: auto;
            font-size: inherit;
            text-transform: none;
        }
    }
}

.form-row__field__error {
    margin-bottom: 0;
    font-size: 12px;
    background-color: config.$color-white;
    color: config.$color-red;
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: -20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    @include responsive.target(lg, xxl) {
        margin-bottom: -20px;
    }

}