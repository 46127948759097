/*********** Colors ***********/

$color-black: #000000;
$color-white: #ffffff;
$color-red: #B92F31;

/*************************************/

/*********** Breakpoints ***********/

$breakpoints: (
  // Breakpoint Definition
  xs: 0,
  // Extra small screen / phone
  sm: 576px,
  // Small screen / phone
  md: 768px,
  // Medium screen / tablet
  lg: 992px,
  // Large screen / desktop
  xl: 1280px,
  // Extra large screen / wide desktop
  xxl: 1440px,
  // Double extra large screen / wide desktop
);

/******************************/