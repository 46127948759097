@use 'config';
@use 'libs/styles/responsive';
@use 'sass:math';

.reference-logos {
    margin-top: 40px;
    margin: 40px -20px 0 -20px;
    background-color: config.$color-white;
    overflow: hidden;
    padding: 30px 0;

    @include responsive.target(lg, xxl) {
        margin-left: -50px;
        margin-right: -50px;
    }
}

.reference-logos__scroller {
    position: relative;
    width: 100vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;

    @include responsive.target(lg, xxl) {
        height: 60px;
    }

    // &:hover,
    &.stop {
        .reference-logos__scroller__inner {
            animation-play-state: paused;
        }
    }

    &+& {
        margin-top: 30px;

        @include responsive.target(lg, xxl) {
            margin-top: 60px;
        }
    }
}

.reference-logos__scroller__inner {
    height: inherit;
    display: flex;
    will-change: transform;
    white-space: nowrap;

    $duration: 60s;

    &--primary {
        animation: scroll-horizontal $duration linear infinite;
        animation-delay: -#{$duration};
    }

    &--secondary {
        animation: scroll-horizontal2 $duration linear infinite;
        animation-delay: math.div($duration, -2);
    }

    &.reference-logos__scroller__inner--reverse {
        animation-direction: reverse;
    }

    @include responsive.target(xl, xxl) {
        $duration: 80s;

        &--primary {
            animation: scroll-horizontal $duration linear infinite;
            animation-delay: -#{$duration};
        }

        &--secondary {
            animation: scroll-horizontal2 $duration linear infinite;
            animation-delay: math.div($duration, -2);
        }
    }
}


@keyframes scroll-horizontal {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll-horizontal2 {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-200%);
    }
}


.reference-logos__logo {
    width: 100px;
    max-width: inherit;
    margin: 0 30px;
    height: 50px;
    object-fit: contain;
    flex-shrink: 0;

    @include responsive.target(lg, xxl) {
        width: 160px;
        height: 60px;
        margin: 0 60px;
    }

    @include responsive.target(xl, xxl) {
        width: auto;
    }
}