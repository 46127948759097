@use 'config';
@use 'libs/styles/responsive';


.products-intro {
    @include responsive.target(sm, xxl) {
        width: 72.24%;
        max-width: 865px;
        margin: auto;
    }
}

.products-container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin: 20px auto;
    width: 260px;
    min-width: 50vw;
    max-width: 100%;

    a {
        text-decoration: none;
    }

    @include responsive.target(lg, xxl) {
        flex-direction: row;
        width: 1280px; // acts as max-width
        margin: 60px auto;
        max-width: 70vw;
        gap: 50px;

        &>* {
            flex: 1 1 0;
            width: 0;
        }
    }

    @include responsive.target(xl) {
        gap: 80px;
        max-width: 82vw;
    }

    @include responsive.target(xxl) {
        gap: 110px;
        max-width: 100%;
        min-width: unset;
    }
}

.product-teaser {
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 1 / 1;
    border-radius: 20px;
    overflow: hidden;
    background-color: config.$color-red;
}

.product-teaser__image {
    flex-grow: 1;
    object-fit: cover;
    transition: filter 0.2s ease-in;

    a:hover & {
        filter: brightness(0.85);
    }
}

.product-teaser__name {
    position: relative;
    color: config.$color-white;
    margin-bottom: 0;
    font-weight: 500;
    text-transform: uppercase;
    padding: 25px 15px;
    letter-spacing: 0.1em;
    text-decoration: none;
    font-size: 13px;

    @include responsive.target(lg, xxl) {
        padding: 15px;
        font-size: 16px;
    }

    @include responsive.target(xl, xxl) {
        white-space: nowrap;
    }

    a & {
        padding-left: 45px;

        &::before {
            position: absolute;
            left: 20px;
            top: calc(50% - 8px);
            content: " ";
            width: 15px;
            height: 15px;
            background: url('/static/img/arrow-down.svg') 0 0 no-repeat;
            background-size: contain;
            transform: rotate(-90deg);
            filter: brightness(0) invert(1);
            transition: transform 0.2s ease-out;
        }
    }

    a:hover & {
        &::before {
            transform: rotate(-90deg) scale(1.15);
        }
    }
}